
// 订单来源
export const columns_source = [
	// {
	// 	title: "小程序商城",
	// 	dataIndex: "1",
	// 	align: "center",
	// },
	{
		title: "小程序",
		dataIndex: "2",
		align: "center",
	},
	{
		title: "PC官网",
		dataIndex: "5",
		align: "center",
	},
	{
		title: "手机官网",
		dataIndex: "6",
		align: "center",
	},
	{
		title: "客服后台下单",
		dataIndex: "10",
		align: "center",
	},
	// {
	// 	title: "客服后台导入",
	// 	dataIndex: "11",
	// 	align: "center",
	// },
	{
		title: "网点后台下单",
		dataIndex: "15",
		align: "center",
	},
	{
		title: "美团",
		dataIndex: "20",
		align: "center",
	},
	{
		title: "饿了么",
		dataIndex: "21",
		align: "center",
	},
	{
		title: "京东",
		dataIndex: "22",
		align: "center",
	},
	{
		title: "天猫",
		dataIndex: "23",
		align: "center",
	},
]

// 订单来源
export const columns_source_simple = [
	// {
	// 	title: "小程序商城",
	// 	dataIndex: "1",
	// 	align: "center",
	// },
	{
		title: "小程序",
		dataIndex: "2",
		align: "center",
	},
	{
		title: "PC官网",
		dataIndex: "5",
		align: "center",
	},
	{
		title: "手机官网",
		dataIndex: "6",
		align: "center",
	},
	{
		title: "客服后台下单",
		dataIndex: "10",
		align: "center",
	},
	// {
	// 	title: "客服后台导入",
	// 	dataIndex: "11",
	// 	align: "center",
	// },
	{
		title: "网点后台下单",
		dataIndex: "15",
		align: "center",
	},
	{
		title: "第三方平台（天东美饿）",
		dataIndex: "third_plat",
		align: "center",
		width: 220,
	},
	// {
	// 	title: "美团",
	// 	dataIndex: "20",
	// 	align: "center",
	// },{
	// 	title: "饿了么",
	// 	dataIndex: "21",
	// 	align: "center",
	// },
	// {
	// 	title: "京东",
	// 	dataIndex: "22",
	// 	align: "center",
	// },
	// {
	// 	title: "天猫",
	// 	dataIndex: "23",
	// 	align: "center",
	// },
]


// 订单支付方式
export const columns_pay = [
	// {
	// 	title: "钱包",
	// 	dataIndex: "1",
	// 	align: "center",
	// },
	// {
	// 	title: "礼品卡支付",
	// 	dataIndex: "2",
	// 	align: "center",
	// },
	// {
	// 	title: "实体卡",
	// 	dataIndex: "3",
	// 	align: "center",
	// },
	{
		title: "微信",
		dataIndex: "10",
		align: "center",
	},
	{
		title: "支付宝",
		dataIndex: "11",
		align: "center",
	},
	// {
	// 	title: "第三方平台",
	// 	dataIndex: "20",
	// 	align: "center",
	// },
	{
		title: "货到付款",
		dataIndex: "30",
		align: "center",
	},
	{
		title: "其它",
		dataIndex: "40",
		align: "center",
	},
	// {
	// 	title: "挂账",
	// 	dataIndex: "50",
	// 	align: "center",
	// },
	{
		title: "挂账-京东自营",
		dataIndex: "50_amount_data",
		align: "center",
		slots: {
			customRender: "50_amount_data",
		},
		slotsType: "format",
		slotsFunc: (val) => {return val[10]},
	},
	{
		title: "挂账-京东",
		dataIndex: "50_amount_data",
		align: "center",
		slots: {
			customRender: "50_amount_data",
		},
		slotsType: "format",
		slotsFunc: (val) => {return val[11]},
	},
	{
		title: "挂账-美团",
		dataIndex: "50_amount_data",
		align: "center",
		slots: {
			customRender: "50_amount_data",
		},
		slotsType: "format",
		slotsFunc: (val) => {return val[12]},
	},
	{
		title: "挂账-天猫",
		dataIndex: "50_amount_data",
		align: "center",
		slots: {
			customRender: "50_amount_data",
		},
		slotsType: "format",
		slotsFunc: (val) => {return val[13]},
	},
	// {
	// 	title: "挂账-悦才",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[14]},
	// },
]



// 订单支付方式（财务账户）
export const columns_pay_simple = [
	// {
	// 	title: "钱包",
	// 	dataIndex: "1",
	// 	align: "center",
	// },
	// {
	// 	title: "礼品卡支付",
	// 	dataIndex: "2",
	// 	align: "center",
	// },
	// {
	// 	title: "实体卡",
	// 	dataIndex: "3",
	// 	align: "center",
	// },
	{
		title: "微信",
		dataIndex: "10",
		align: "center",
	},
	{
		title: "支付宝",
		dataIndex: "11",
		align: "center",
	},
	{
		title: "货到付款",
		dataIndex: "30",
		align: "center",
	},
	{
		title: "其它",
		dataIndex: "40",
		align: "center",
	},
	{
		title: "挂账",
		dataIndex: "50",
		align: "center",
	},
	// {
	// 	title: "挂账-京东自营",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[10]+" 元"},
	// },
	// {
	// 	title: "挂账-京东",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[11]+" 元"},
	// },
	// {
	// 	title: "挂账-美团",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[12]+" 元"},
	// },
	// {
	// 	title: "挂账-天猫",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[13]+" 元"},
	// },
	// {
	// 	title: "挂账-悦才",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[14]+" 元"},
	// },
]


export default {
  columns_source,
  columns_source_simple,
  columns_pay,
  columns_pay_simple
}
